.nav_link {
    text-decoration: none;
    color: black;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    padding: 0 3px 0 3px;
    border-bottom: 3px transparent solid;
}

.nav_link:hover {
    border-bottom: 3px #5260FE solid;
    color: #5260FE;
}

.home {
    color: white;
    transition-duration: 0.3s;
}

.all {
    color: black;
    transition-duration: 0.3s;
}
