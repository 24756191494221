.link {
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}

.link:hover {
    color: #5260FE;
    border-bottom: none;
}